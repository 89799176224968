import * as React from 'react';
import { FaRegClock, FaBullseye } from 'react-icons/fa'
import picLogo from '../../assets/picLogo.svg';
import picTextLogo from '../../assets/picTextLogo.svg';
import { api } from '../../util';


const Accuracy: React.FC<{
  changeTab: Function,
  rrmse: number,
  verificationCounts: api.VerificationCount[],
  displayName: string
}> = (props) => (
  <div className="App d-flex flex-column">

  {/* Nav bar */}
    <NavAndSubNav changeTab={props.changeTab} displayName={props.displayName} />

    <div style={{...styles.cardGrid, paddingTop: '124px'}} className="justify-content-center">

    {/* Error card */}
      <section className="d-flex align-items-center justify-content-center" style={{...styles.card, fontSize: '25px'}}>
        <div className="d-flex align-items-center" style={{color: '#666'}}>
          <span style={styles.labelText}>Relative Root Mean<br />Squared Accuracy </span>
          <span className="ml-2">:</span>
        </div>
        <span className="ml-5">{100 - props.rrmse}%</span>
      </section>

    {/* Historical Counts */}
      <section style={{...styles.card, overflowX: 'scroll'}} className="mb-3">
        <div style={styles.historicalCountsContainer}>
          <div style={{...styles.historicalCountsTable, ...styles.tableHeader}}>
            <span>Timestamp</span>
            <span>Count Inputted</span>
            <span>Estimation</span> 
          </div>
          {props.verificationCounts.map((v: api.VerificationCount, idx: number) => {

            return (
              <HistoricalCountRow 
                key={idx}
                row={v}
              />
            );
          })}
        </div>
      </section>
  
    </div>
  </div>
);

const NavAndSubNav: React.FC<{changeTab: Function, displayName: string}> = ({ changeTab, displayName }) => (
  <section style={styles.navHeader}>

  <div style={{...styles.globalNav,...styles.cardGrid}} className="justify-content-center align-items-center">
    <div className="d-flex justify-content-between">
      <img src={picLogo} alt="logo" className="d-flex d-sm-none"/>
      <img src={picTextLogo} alt="branding text" className="d-none d-sm-flex" />

      <div className="d-flex justify-content-between align-items-center">
        <div onClick={() => changeTab()}style={styles.inactivePage} className="d-flex flex-column flex-sm-row align-items-center navpages">
          <FaRegClock className="mr-sm-2" id="navIcon"/>
          <span className="navtext">Live Data</span>
        </div>

        <div style={styles.navDiv} />

        <div style={styles.activePage} className="d-flex flex-column flex-sm-row align-items-center navpages">
          <FaBullseye className="mr-sm-2" id="navIcon"/>
          <span className="navtext">Accuracy</span>
        </div>
      </div>
    </div>
  </div>
  
  {/* Location subnav */}
  <div style={{...styles.locationNav, ...styles.cardGrid}} className="justify-content-center align-items-center">
    {displayName}
    {/* hi im here */}
  </div>

</section>
)

const HistoricalCountRow: React.FC<{row: api.VerificationCount}> = ({ row }) => (
  <div style={styles.historicalCountsTable} className="mt-3">
    <span>{row.timestamp}</span>
    <span>{row.headcount}</span>
    <span>{row.currentEstimation || 'N/A'}</span>
  </div>
)

const styles: {[key: string]: React.CSSProperties}  = {

  cardGrid: {
    display: 'grid',
    gridGap: '24px',
    gridTemplateColumns: 'minmax(250px, 600px)',
  },

  navHeader: {
    position: 'fixed',
    top: '0',
    width: '100%',
    borderBottom: 'solid .5px rgba(0, 0, 0, .4)',
  },

  globalNav: {
    backgroundColor: '#04060D',
    height: '50px',
    padding: '0px 20px',
  },

  activePage: {
    color: 'white',
    fontWeight: 'bold',
  },

  inactivePage: {
    color: '#B3B3B3',
  },

  navDiv: {
    height: '36px',
    width: '1px',
    backgroundColor: '#ccc',
    margin: '0 16px',
  },

  locationNav: {
    backgroundColor: 'white',
    padding: '10px 0',
    fontSize: '20px',
  },

  card: {
    backgroundColor: 'white',
    boxShadow: '0 2px 6px -3px rgba(0, 0, 0, .15)',
    padding: '24px 16px',
    border: 'solid 1px rgba(0, 0, 0, .1)',
  },

  labelText: {
    color: '#666',
    fontSize: '16px',
  },

  historicalCountsContainer: {
    display: 'grid',
    width: '580px'
  },

  tableHeader: {
    color: '#666',
    fontSize: '14px',
    borderBottom: 'solid 1px #ccc',
  },

  historicalCountsTable: {
    display: 'grid',
    gridTemplateColumns: '140px 110px 135px 135px',
    gridGap: '20px',
    textAlign: 'left',
  }

}


export default Accuracy;

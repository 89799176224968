/**
 * Whenever a user isn't logged in, we need send them to our Cognito oauth endpoint.
 * To do this, we notify them that we're about to take them out of the page and into another
 * one to login.
 */
import React from 'react';
import { auth } from '../../util';
import { FaRegClock, FaBullseye } from 'react-icons/fa'
import picLogo from '../../assets/picLogo.svg';
import picTextLogo from '../../assets/picTextLogo.svg';

const LoginLink = () => (
  <div className="App d-flex flex-column">

    <section style={styles.loginModal} className="d-flex justify-content-center align-items-center">
      <div style={styles.card} className="d-flex flex-column align-items-center">
        <span className="mb-4" style={{fontSize: '20px'}}>Please login to use Occuspace Accuracy Checker</span>
        <button 
        style={{width: '150px', fontSize: '20px'}}
        className="btn btn-primary" 
        onClick={() => {
          window.location.href = auth.LOGIN_URL;
        }}
      >
        Login
      </button>
      </div>
    </section>

    <div style={{...styles.globalNav,...styles.cardGrid}} className="justify-content-center align-items-center">
      <div className="d-flex justify-content-between">
        <img alt="logo" src={picLogo}  className="d-flex d-sm-none"/>
        <img alt="branding text" src={picTextLogo} className="d-none d-sm-flex" />

        <div className="d-flex justify-content-between align-items-center">
          <div style={styles.inactivePage} className="d-flex flex-column flex-sm-row align-items-center navpages">
            <FaRegClock className="mr-sm-2" id="navIcon"/>
            <span className="navtext">Live Data</span>
          </div>

          <div style={styles.navDiv} />

          <div style={styles.inactivePage} className="d-flex flex-column flex-sm-row align-items-center navpages">
            <FaBullseye className="mr-sm-2" id="navIcon"/>
            <span className="navtext">Accuracy</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const styles: {[key: string]: React.CSSProperties}  = {

  loginModal: {
    backgroundColor: 'rgba(0, 0, 0, .4)',
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: '0',
  },

  cardGrid: {
    display: 'grid',
    gridGap: '24px',
    gridTemplateColumns: 'minmax(250px, 600px)',
  },

  card: {
    backgroundColor: 'white',
    boxShadow: '0 2px 6px -3px rgba(0, 0, 0, .15)',
    padding: '24px 16px',
    border: 'solid 1px rgba(0, 0, 0, .1)',
    maxWidth: '600px',
  },

  navHeader: {
    position: 'fixed',
    top: '0',
    width: '100%',
    borderBottom: 'solid .5px rgba(0, 0, 0, .4)',
  },

  globalNav: {
    backgroundColor: '#04060D',
    height: '50px',
    padding: '0px 20px',
  },

  activePage: {
    color: 'white',
    fontWeight: 'bold',
  },

  inactivePage: {
    color: '#B3B3B3',
  },

  navDiv: {
    height: '36px',
    width: '1px',
    backgroundColor: '#ccc',
    margin: '0 16px',
  },

}

export default LoginLink;

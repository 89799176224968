import queryString, { ParsedQuery } from 'query-string';
import { config } from '.';

export const AUTH_URL = 'https://occuspace-login.auth.us-west-2.amazoncognito.com/oauth2'
export const LOGIN_URL =
  `${AUTH_URL}/authorize?response_type=token&client_id=7mt51fgibhf4p8fld6d4q3kp3d&redirect_uri=${config.CALLBACK_URL}`;

export function setToken() {
  if (!window.location.hash) {
    throw new Error('Invalid URL to extract token.');
  }

  const tokens: ParsedQuery = queryString.parse(window.location.hash);
  if (!('access_token' in tokens)) {
    throw new Error('No access token found in callback URL hash.');
  }

  localStorage.setItem('accessToken', tokens.access_token as string);
}

/**
 * Return the token or null if there is none.
 */
export function getToken(): string | null {
  const token: string | null = localStorage.getItem('accessToken');
  return token;
}

export function removeToken() {
  localStorage.removeItem('accessToken');
}

export function isLoggedIn(): boolean {
  // currently configured so if there's no token it throws
  return getToken() ? true : false;
}
/**
 * URL's used by this app
 * @NOTE need to add IP address callback url to be able to test
 * on device. So even though this will dynamically select the correct
 * development address to make requests to, they won't be authenticated
 * because cognito doesn't know that requests can come from our IP address.
 * Also can't configure it to because it's an address that's not local host, 
 * it would need to have HTTPS
 */
const PROD_API = 'https://api.occuspace.io'
const DEV_PORT = ':8081'
// default to prod API, but overwrite if actually in dev mode
export let API_URL = PROD_API
if (process.env.NODE_ENV === 'development') {
  // get the address the webserver is and remove the port
  const appWebserverAddr = window.location.origin
  const PORT_REGEX = /:\d+/
  API_URL = appWebserverAddr.replace(PORT_REGEX, DEV_PORT)
}
console.log('Info :: API making requests to ', API_URL)
    
export const CALLBACK_URL: string = `${window.location.origin}/authorized`;

import * as React from 'react';
import Accuracy from './Accuracy';
import { api } from '../../util';
import { Loader } from '../../components';

/**
 * @NOTE THIS IS SO BAD: to format the names for brightspot release,
 * im going to just format the names here and have a fallback. We don't have to worry about much
 * because there is only 2 zones currently...
 */
const getDisplayName = (zoneId: number | undefined, zoneName: string): string => {
  if (typeof (zoneId) === 'undefined') {
    return '';
  }

  if (zoneId === 93) {
    return `Honeywell > ${zoneName}`;
  }
  else if (zoneId === 95) {
    return `Brightspot > ${zoneName}`;
  }

  // fallback: if we don't have the customer just send on over the name... 
  // they should know where they are  :)
  return zoneName;
}

interface Props {
  changeTab: Function
}

interface State {
  loading: boolean,
  rrmse: number,
  verificationCounts: api.VerificationCount[],
  zoneName: string,
  zoneId: number
}

/**
 * Taking a couple of gracious assumptions here:
 * 1) I'm going to be only seeing one zone.
 * 2) We don't have any dynamic data. Just one data fetch.
 */
export default class AccuracyContainer extends React.Component<Props, State> {
  state = {
    loading: true,
    rrmse: 0,
    verificationCounts: [],
    zoneName: '',
    zoneId: 0
  }

  componentDidMount() {
    let zone: {zoneId: number, zoneName: string};
    api.getZones()
    .then(zones => {
      zone = zones[0];      // ASSumption
      return Promise.all([
        api.getAccuracy(zone.zoneId),
        api.getVerificationCounts(zone.zoneId)
      ])
    })
    .then((responses: any[]) => {
      this.setState({
        rrmse: responses[0],
        verificationCounts: responses[1],
        zoneName: zone.zoneName,
        zoneId: zone.zoneId
      });
    })
    .finally(() => {
      this.setState({ loading: false });
    })
  }  

  render() {
    if (this.state.loading) {
      return <Loader isLoading={true} />;
    }

    const { rrmse, verificationCounts, zoneId, zoneName } = this.state;
    return (
      <Accuracy 
        displayName={getDisplayName(zoneId, zoneName)}
        rrmse={rrmse}
        verificationCounts={verificationCounts}
        changeTab={this.props.changeTab} 
      />
    );
  }
}
import * as React from 'react';

/**
 * Wrapper for intervalled hooks.
 * src: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export default function useInterval(callback: Function, delay: number) {
  const savedCallback: React.MutableRefObject<Function|undefined> = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  /**
   * Setup the interval.
   */
  React.useEffect(() => {
    function tick() {
      if (savedCallback !== undefined && typeof savedCallback.current === 'function') {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
import * as React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import { LoginLink, Data } from './screens';
import { api, auth } from './util';
import * as types from './types';
import { Loader } from './components';

export default function App() {
  return (
    <Router>
      {/* <Route exact path='/' render={() => <Test_HomepageAuthResolver />} /> */}
      <Route exact path='/' render={() => <HomepageAuthResolver />} />

      <Route exact path='/authorized' render={(match) => {
        auth.setToken();

        // for now, we just need to make sure it works. even if it's an extra
        // hop, i think ensuring that we don't get stuck on an expired token is
        // worse than having an extra load
        window.location.href = '/';
        return <></>
      }}/>

      <Route exact path='/login' render={() => <LoginLink />} />
    </Router>
  )
}

function HomepageAuthResolver() {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [cardData, setCardData] = React.useState<types.ZoneCardData[]>();
  const [customer, setCustomer] = React.useState<types.Customer>();

  // once comp mounts, see if you're logged in.
  React.useEffect(() => {
    const cards = api.isLoggedIn()
      .then(isLoggedIn => {
        setLoggedIn(isLoggedIn);
        return api.getZoneCards();
      })
      .then(d => {
        console.log('DATA RECEIVED: ', d);
        setCardData(d)
      })
    const cus = api.getCustomer().then(c => setCustomer(c));

    Promise.all([cards, cus]).finally(() => setLoading(false));
  }, [])

  // wait to determine if we're actually logged in
  if (loading)        return <Loader isLoading={true} />
  else if (!loggedIn) return <LoginLink />
  else if (!cardData) return <div>Error loading Application</div>
  else if (!customer) return <Loader isLoading={true} />

  // once we're done loading go to the right location
  return <Data customer={customer} cardData={cardData} uninstalledZones={[]} />
}



// import * as React from 'react';
// import * as types from '../../types';

// interface DataProps {
//   cardData: types.ZoneCardData[]
// }
// export default function Data(props: DataProps) {
//   const { cardData } = props;

//   return <div>im the new data</div>;
// }

// make max's final product this when ready
import Data from './MaxMultiZone';
export default Data;
import * as React from 'react';
import Select from 'react-select';

export interface Zone {
  zoneId: number
  zoneName: string
}
interface SelectOpt {
  label: string
  value: number
}

const convertZoneObjs = (zoneObjs: Zone[]): SelectOpt[] => zoneObjs.map(z => ({ 
  label: z.zoneName,
  value: z.zoneId
}));


interface Props {
  options: Zone[]
}

export default function Selector(props: Props) {
  const opts = convertZoneObjs(props.options);

  return (
    <Select 
      defaultValue={opts[0]}
      options={opts}
    />
  )
}

import * as React from 'react';
import { api } from '../../util';
import styled from 'styled-components';
import { FaBullseye } from 'react-icons/fa';
import picLogo from '../../assets/picLogo.svg';
import picTextLogo from '../../assets/picTextLogo.svg';

interface Props {
  id: number
}

export default function Zone(props: Props) {
  const getAccuracy = () => api.getAccuracy(props.id);
  const getEstimations = () => api.getEstimations(props.id);
  const getVerificationCounts = () => api.getVerificationCounts(props.id);
  const getData = async () => {
    const [accuracy, estimations, verificationCounts] = await Promise.all([
      getAccuracy(), 
      getEstimations(),
      getVerificationCounts()
    ]);
    return { 
      accuracy,
      verificationCounts,
      estimations: estimations.map(({ count, timestamp, zoneId }) => ({
        zoneId,
        timestamp,
        count: count !== null ? Math.max(count, 0) : count
      }))
    };
  }

  React.useEffect(() => {
    getData()
      .then(d => console.log('DATA IS: ', d))
      .catch(err => {
        if (err === 'Unauthorized') {
          window.location.href = '/login';
          return;
        }
      })
  })

  return (
    <div>

      <AppHeader>
        <MaxContainer className="justify-content-between">
          <LogoContainer>
            <img alt="logo" src={picLogo}  className="d-flex d-sm-none"/>
            <img alt="branding text" src={picTextLogo} className="d-none d-sm-flex" />
          </LogoContainer>
          <div>
            <FaBullseye />
            <span>
              Accuracy
            </span>
          </div>
        </MaxContainer>
      </AppHeader>

      <LocationHeader>
        <span className="inactive">
          Prescriptive Data
        </span>
      </LocationHeader>

      <CardContainer>
        <MaxContainer>


        </MaxContainer>
      </CardContainer>
    </div>
  )
}

// constant vars for header here
const GUTTER = `
padding-left: 20px;
padding-right: 20px;
`;

const HEADER_HEIGHT = '40px';
const OCCU_BLACK = '#14151a';

const MaxContainer = styled.div`
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const FixedHeader = styled.div`
  ${GUTTER};
  height: ${HEADER_HEIGHT};
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
`

const AppHeader = styled(FixedHeader)`
  justify-content: space-between;
  background-color: ${OCCU_BLACK};
  color: white;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    color: white;
    height: 18px;
    margin-right: 6px;
  }
`
const LocationHeader = styled(FixedHeader)`
  background-color: white;
  justify-content: center;
  top: ${HEADER_HEIGHT};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .05);
  
  span .inactive {
    color: ${OCCU_BLACK};
  }
`

const LogoContainer = styled.div`
  height: 30px;

  img {
    height: 100%;
  }
`

const CardContainer = styled.div`
  padding-top: calc(${HEADER_HEIGHT} + ${HEADER_HEIGHT} + 24px);
  display: flex;
  flex-direction: column;
  ${GUTTER};
`


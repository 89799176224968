import * as React from 'react';
import LiveData from './LiveData';
import { api } from '../../util';
import moment from 'moment';
import { Loader } from '../../components';
import { Zone } from '../../components/selector/Selector';

/**
 * @NOTE THIS IS SO BAD: to format the names for brightspot release,
 * im going to just format the names here and have a fallback. We don't have to worry about much
 * because there is only 2 zones currently...
 */
const getDisplayName = (zoneId: number | undefined, zoneName: string): string => {
  if (typeof(zoneId) === 'undefined') {
    return '';
  }

  if (zoneId === 93) {
    return `Honeywell > ${zoneName}`;
  }
  else if (zoneId === 95) {
    return `Brightspot > ${zoneName}`;
  }
  
  // fallback: if we don't have the customer just send on over the name... 
  // they should know where they are  :)
  return zoneName;
}
   
interface Props {
  changeTab: Function
}

interface State {
  loading: boolean
  count: string
  zoneName?: string
  zoneId?: number
  currEstimation?: number
  timestamp?: string
  estimations?: any[]
  stage: number
  zones: Zone[]
};

interface CountEstimation {
  zoneId: number,
  timestamp: string,
  count: number
}
interface GetEstimations {
  estimations: CountEstimation[],
  currEstimation: CountEstimation,
  timestamp: string
}

const TIMESTAMP_FMT: string = 'MM/DD/YY, hh:mma';

export default class LiveDataContainer extends React.Component<Props, State> {
  intervalId: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      zoneName: '',
      zoneId: 0,
      zones: [],
      currEstimation: 0,
      estimations: [],
      timestamp: '',
      count: '',
      stage: 0
    }
  }

  static getEstimations(zoneId: number): Promise<GetEstimations> {
    return api.getEstimations(zoneId)
    .then((estimations: CountEstimation[]) => {
      const currEstimation = estimations[estimations.length - 1];
      return {
        estimations,
        currEstimation,
        timestamp: moment().format(TIMESTAMP_FMT)
      };
    });
  }

  /**
   * Fetch current estimation and the last x
   * im making a stupid assumption because our users only have one zone per.
   * im just going to drop them into one so we don't need nav
   */
  componentDidMount() {
    // set the timer to refresh on clock ticks
    const now = new Date().getTime();
    const nextMinute = 60000 - (now % 60000) ;
    setTimeout(() => {
      this.refresh();
      this.intervalId = setInterval(this.refresh, 60 * 1000);
    }, nextMinute);

    let zone: { zoneId: number, zoneName: string };
    api.getZones()
    .then((zones) => {
      zone = zones[0]; // the assumption..
      this.setState({ zones });
      return LiveDataContainer.getEstimations(zone.zoneId);
    })
    .then((estObj: GetEstimations) => {
      this.setState({
        zoneName: zone.zoneName,
        zoneId: zone.zoneId,
        estimations: estObj.estimations as [],
        currEstimation: estObj.currEstimation ? estObj.currEstimation.count : -1,
        timestamp: estObj.timestamp
      });

      // to do: run concuerrntly
      return api.getCurrentEstimation(zone.zoneId)
    })
    .then(currEstimation => {
      this.setState({ currEstimation });
    })
    .finally(() => { 
      console.log('IM RIGHT HERE!')
      this.setState({ loading: false });
    });
  }

  /**
   * Remove the interval
   */  
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  /**
   * Pull a new estimation for this zone
   */
  refresh = () => {
    console.log('Running refresh')

    // if this happens before we have a zoneID, just update the clock
    if (!this.state.zoneId) {
      this.setState({
        timestamp: moment().format(TIMESTAMP_FMT)
      })
      return;
    }

    const { zoneId } = this.state;
    LiveDataContainer.getEstimations(zoneId)
    .then((estObj: GetEstimations) => {
      console.log(estObj, 'is the data');
      this.setState({
        estimations: estObj.estimations as [],
        currEstimation: estObj.currEstimation ? estObj.currEstimation.count : -1,
        timestamp: moment().format(TIMESTAMP_FMT)
      });

      return api.getCurrentEstimation(zoneId)
    })
    .then(currEstimation => {
      this.setState({ currEstimation });
    })
    .catch(err => {
      console.log('ERROR: ', err);
      alert('Error fetching data. Please contact Occuspace customer service.');
    })
  }

  /**
   * Send headcount to the DB
   */
  postHeadcount = () => {
    const { zoneId, currEstimation, count } = this.state;

    // guard 
    if (!zoneId || currEstimation === undefined) {
      alert('We have a problem processing your request. Please notify Occuspace Staff.');
      return;
    }

    if (!/^\d+$/.test(count)) {
      alert('Please input a valid number');
      return;
    }

    api.postHeadcount(zoneId, parseInt(count), currEstimation)
    .then(() => {
      alert('Headcount successfully submitted.');
      this.setState({ count: '' });
      this.refresh();
    })
    .catch(err => {
      console.log(err);
      alert('Error submitting headcount. Please contact you occuspace team at info@occuspace.io.');
    })
    .finally(() => {
      this.setState({ stage: 0 })
    })
  }

  render() {
    console.log('Is this.state.loading? ', this.state.loading)
    if (this.state.loading) {
      return <Loader isLoading={true} />;
    }

    const { 
      zoneId,
      zoneName,
      currEstimation,
      estimations,
      timestamp,
      count
    } = this.state;

    if (!zoneName || typeof(currEstimation) === 'undefined' || !estimations || !timestamp) {
      return <Loader isLoading={true} />;
    }

    /**
     * @NOTE THIS IS SO BAD: to format the names for brightspot release,
     * im going to just format the names here and have a fallback. We don't have to worry about much
     * because there is only 2 zones currently...
     */
    const displayName = getDisplayName(zoneId, zoneName);

    return (
      <LiveData 
        displayName={displayName}
        zoneName={zoneName} 
        currEstimation={currEstimation}
        estimations={estimations}
        timestamp={timestamp}
        refresh={this.refresh}
        inputCount={evt => {
          this.setState({ count: evt.target.value })
        }}
        postHeadcount={this.postHeadcount}
        count={count}
        changeTab={this.props.changeTab}
        moveStage={(delta: number) => {
          if (!/^\d+$/.test(count)) {
             alert('Please input a valid number');
             return;
          }
          this.setState({ stage: this.state.stage + delta });
        }}
        stage={this.state.stage}
        zones={this.state.zones}
      />
    )
  }
}
import React from 'react';

const Loader: React.FC<{isLoading: boolean}> = () => (
  <div className="loader" style={{
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  }} />
);

export default Loader;
/**
 * @TODO HUGE IF STATEMENT
 * please fix and incorporate sub view locations zones
 */
import { auth } from '../util';
import moment from 'moment';
import { config } from '.';
import { Zone } from '../components/selector/Selector';
import * as types from '../types';

function request(method: string, endpoint: string, bodyParams?: object): Promise<any> {
  const token = auth.getToken();
  
  return fetch(`${config.API_URL}/${endpoint}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(bodyParams)
  })
  .then((resp: Response) => {
    if (resp.status !== 200) {
      // throw new Error('Non-200 response');
      return Promise.reject(`Non-200 Repsonse (${resp.status})`);
    }
    return resp.json();
  })
  .catch(err => {
    if (/401/.test(err)) {
      return Promise.reject('Unauthorized');
    }
    console.log(`Error making a request to endpoint /${endpoint}: `, err);
    // throw new Error('Request failed.');
    return Promise.reject(err);
  });
}

async function typedRequest<S>(method: string, endpoint: string, bodyParams ?: object): Promise<S> {
  try {
    return await request(method, endpoint, bodyParams) as S;
  }
  catch(err) {
    throw err;
  }
}

/**
 * @TODO change the endpoint to reflect what the actua; 
 */
export const isLoggedIn = (): Promise<boolean> => {
  if (!auth.getToken()) {
    return Promise.resolve(false);
  }

  return request('GET', 'occucheck/is-logged-in')
    .then(({ isLoggedIn }) => isLoggedIn);
}
export const getUserEmail = (): Promise<string> => {
  if (!auth.getToken()) {
    return Promise.reject('No token')
  }
  return request('GET', 'occucheck/is-logged-in')
    .then(({ email }) => email)
}

export interface GetCustomer {
  customer: {
    id: number
    name: string
  }
}
export const getCustomer = async (): Promise<types.Customer> => {
  const { customer } = await typedRequest<GetCustomer>('GET', 'occucheck/customer');
  return customer;
}

export interface InstalledZone {
  zoneId: number
  zoneName: string
  installed: boolean
  installDate: string|null
}
export interface GetInstalledZonesResponse {
  installedZones: InstalledZone[]
}
export const getInstalledZones = (): Promise<GetInstalledZonesResponse|null> => {
  return request('GET', 'occucheck/installed-zones')
    .then(installed => {
      console.log('INSTALLED IS: ', installed);
      return installed as GetInstalledZonesResponse;
    })
}

export const getZones = (): Promise<Zone[]> => {
  return request('GET', 'occucheck/zones')
    .then(({ zones }) => zones);
}

export const getCurrentEstimation = (zoneId: number): Promise<number> => {
  return request('GET', `occucheck/zone/${zoneId}/current-estimation`)
  .then(({ count }) => count);
}

export interface OccucheckEstimation {
  zoneId: number,
  timestamp: string,
  count: number
}
export const getEstimations = (zoneId: number): Promise<OccucheckEstimation[]> => {
  return request('GET', `occucheck/zone/${zoneId}/estimations`)
  .then(({ estimations }: { estimations: OccucheckEstimation[] }) => estimations.map(e => (
    {
      ...e,
      timestamp: moment(e.timestamp).format('h:mma')
    }
  )));
}

/**
 * Send headcount to the DB: if it works we'll have 200 response
 */
export const postHeadcount = (zoneId: number, count: number, estimated: number): Promise<any> => {
  return request('POST', `occucheck/zone/${zoneId}/count`, { count, estimated });
}

export const getAccuracy = (zoneId: number): Promise<number> => {
  return request('GET', `occucheck/zone/${zoneId}/accuracy`)
    .then((accuracy: { rrmse: number }): number => 100 - accuracy.rrmse);
}

export interface VerificationCount {
  zoneId: number,
  timestamp: string,
  estimated: number,
  headcount: number,
  currentEstimation: number|null
}
export const getVerificationCounts = (zoneId: number): Promise<types.VerificationCount[]> => {
  return request('GET', `occucheck/zone/${zoneId}/counts`)
    .then((counts: { verifications: types.VerificationCount[] }): types.VerificationCount[] => {
    return counts.verifications.map(c => ({
      ...c, 
      timestamp: moment(c.timestamp).format('M/D/YY, h:mma')
    }));
  });
}

export interface ZoneCount {
  zoneId: number
  zoneName: string
  count: number | null
  accuracy: number | null
} 
export async function getZoneCount(id: number, name: string): Promise<ZoneCount> {
  const [accuracy, estimations] = await Promise.all([
    getAccuracy(id), 
    getEstimations(id)
  ]);

  return {
    accuracy,
    zoneId: id,
    zoneName: name,
    count: Math.max(estimations[0].count, 0),
  }
}


//------- When i stopped being an idiot:
export interface AuthResolverData {
  isLoggedIn: boolean
  zones: types.Zone[]
  customer: types.Customer
}
export async function getAuthResolverData(): Promise<AuthResolverData> {
  const [loggedIn, zones, customer] = await Promise.all([
    isLoggedIn(),
    getZones(),
    getCustomer()
  ])
  return { zones, isLoggedIn: loggedIn, customer };
}

export interface ZoneData {
  accuracy: number
  verificationCounts: types.VerificationCount[]
  count: number
}
export async function getZoneCardData(id: number): Promise<ZoneData> {
  const [estimations, accuracy, verificationCounts] = await Promise.all([
    getEstimations(id),
    getAccuracy(id),
    getVerificationCounts(id)
  ])

  return {
    accuracy,
    verificationCounts,
    count: estimations[0].count
  };
}

export async function getZoneCards(): Promise<types.ZoneCardData[]> {
  let zones = await getZones();

  /**
   * Originally built for channel partners with few locations,
   * verify returns ALL zones for specific customers. This is how
   * we filter for specific locations before we have a sublocation/zone
   * level permission scopes
   */
  const user = await getUserEmail()

  // zones
  const RIMAC = 136
  const MAIN_GYM = 138
  const FIN_AID = 201
  // user groups
  const REC_LOC_USERS = ['julian@occuspace.io', 'bhyatt@ucsd.edu', 'ibrandl@ucsd.edu']
  const FIN_AID_USERS = ['kaharris@ucsd.edu', 'vgg001@ucsd.edu']
  // filter funcs
  interface Zone { zoneId: number }
  const isRecLoc: (z: Zone) => boolean = 
    z => [RIMAC, MAIN_GYM].includes(z.zoneId)
  const isFinAid: (z: Zone) => boolean = 
    z => z.zoneId === FIN_AID
  // see the user type, filter if necessary
  if (REC_LOC_USERS.includes(user)) {
    zones = zones.filter(isRecLoc)
  }
  else if (FIN_AID_USERS.includes(user)) {
    zones = zones.filter(isFinAid);
  }

  const zoneCards = zones.map(z =>
    getZoneCardData(z.zoneId).then(zData => ({ ...z, ...zData }))
  );

  return await Promise.all(zoneCards);
}
import * as React from 'react';
import { FaRegClock, FaBullseye } from 'react-icons/fa'
import picLogo from '../../assets/picLogo.svg';
import picTextLogo from '../../assets/picTextLogo.svg';
import { Selector } from '../../components';
import { Zone } from '../../components/selector/Selector';

// duplicated because i dodn't want cicruclar dependency....
interface CountEstimation {
  zoneId: number,
  timestamp: string,
  count: number
}


const LiveData: React.FC<{
  displayName: string,
  changeTab: Function,
  zoneName: string,
  currEstimation: number,
  timestamp: string,
  zones: Zone[],
  refresh: () => void,
  estimations: any[],
  inputCount: (evt: any) => void,
  postHeadcount: () => void,
  count: string,
  moveStage: Function,
  stage: number
}> = (props) => (
    <div className="App d-flex flex-column">

    {/* Nav & subheader*/}
      <NavAndSubNav changeTab={props.changeTab} zoneName={props.zoneName} displayName={props.displayName} zones={props.zones} />

      <div style={{...styles.cardGrid, paddingTop: '124px'}} className="justify-content-center">

      {/* Live Timestamp
        <section className="d-flex justify-content-center align-items-center" style={{...styles.card, ...styles.valueColor}}>
          <div className="pr-2">
            <span style={styles.bigText}>{props.timestamp}&nbsp;</span> 
          </div>
          <div className="pl-2" onClick={props.refresh}>
            <FaSyncAlt />
          </div>
        </section> */}

      {/* Input */}
      {/* <InputStageOne timestamp={props.timestamp} inputCount={props.inputCount} postHeadcount={props.postHeadcount} count={props.count} /> */}

      {/* Live Count Count
        <section className="d-flex flex-column justify-content-center align-items-center" style={styles.card}>
          <span style={{...styles.bannerText, ...styles.valueColor}}>{props.currEstimation}</span>
          <span style={styles.labelText}>prediction as of timestamp</span>
        </section> */}
      {/* Input */}
      {props.stage === 0 && <InputStageOne timestamp={props.timestamp} inputCount={props.inputCount} postHeadcount={() => props.moveStage(1)} count={props.count} /> }
      {props.stage === 1 && <InputStageTwo timestamp={props.timestamp} headcount={props.count} confirmHeadcount={props.postHeadcount} deny={() => props.moveStage(-1)} /> }

      {/* Recent Counts */}
        <section style={styles.card} className="mb-3">
          <div style={{...styles.recentCountsTable, ...styles.tableHeader, ...styles.labelText}} className="pb-1">
            <span>Most Recent Data</span>
            <span>Prediction</span> 
          </div>
          <div className="d-flex flex-column">
            {props.estimations.map((e: CountEstimation, idx: number) => (
              <RecentCountRow key={idx} timestamp={e.timestamp} count={e.count} />
            ))}
          </div>
        </section>

      </div>

    </div>
);

const NavAndSubNav: React.FC<{ zoneName: string, changeTab: Function, displayName: string, zones: Zone[]}> = (props) => (
  <section style={styles.navHeader}>

    <div style={{...styles.globalNav,...styles.cardGrid}} className="justify-content-center align-items-center">
      <div className="d-flex justify-content-between">
        <img alt="logo" src={picLogo}  className="d-flex d-sm-none"/>
        <img alt="branding text" src={picTextLogo} className="d-none d-sm-flex" />

        <div className="d-flex justify-content-between align-items-center">
          <div style={styles.activePage} className="d-flex flex-column flex-sm-row align-items-center navpages">
            <FaRegClock className="mr-sm-2" id="navIcon"/>
            <span className="navtext">Live Data</span>
          </div>

          <div style={styles.navDiv} />

          <div onClick={() => props.changeTab()} style={styles.inactivePage} className="d-flex flex-column flex-sm-row align-items-center navpages">
            <FaBullseye className="mr-sm-2" id="navIcon"/>
            <span className="navtext">Accuracy</span>
          </div>
        </div>
      </div>
    </div>
    
    {/* Location subnav */}
    <div style={{...styles.locationNav, ...styles.cardGrid, position: 'relative'}} className="justify-content-center align-items-center">
      {/* {props.displayName} */}
      <Selector
        options={props.zones}
      />
    </div>
  </section>
)

const InputStageOne: React.FC<{ timestamp: string, count: string, inputCount: (evt: any) => void, postHeadcount: () => void}> = ({ timestamp, count, inputCount, postHeadcount }) => (
  <section className="d-flex flex-column justify-content-center align-items-center" style={styles.card}>
    <span style={styles.bigText}>{timestamp}&nbsp;</span>
      <input style={{...styles.inputCount, ...styles.buttonRadius}} type="string" className="text-center my-4 inputBtn" value={count} onChange={inputCount} placeholder="Input Count"/>
    {/* <button onClick={postHeadcount} style={{...styles.submitInactiveButton, ...styles.buttonRadius}} className="mt-1"> */}
    <button onClick={postHeadcount} style={!count ? { ...styles.submitInactiveButton, ...styles.buttonRadius } : { ...styles.submitActiveButton, ...styles.buttonRadius }} className="mt-1">
    Enter Count to Submit
    </button>
  </section>
)

const InputStageTwo: React.FC<{headcount: string, timestamp: string, confirmHeadcount: Function, deny: Function}> = ({headcount, timestamp, confirmHeadcount, deny}) => (
  <section className="d-flex flex-column justify-content-center align-items-center" style={styles.card}>
    <span style={styles.bigText}>Submit count of {headcount} at {timestamp}</span> 
    <div className="py-3 w-100 d-flex justify-content-around">
      <button onClick={() => deny()}  className="btn " style={{backgroundColor: '#C42929', color: 'white', width: 100}}>No</button>
      <button onClick={() => confirmHeadcount()} className="btn " style={{ backgroundColor: '#459E29', color: 'white', width: 100}}>Yes</button>
    </div>
  </section>
);

const RecentCountRow: React.FC<{ timestamp: string, count: number }> = ({ timestamp, count }) => (
  <div style={{...styles.recentCountsTable, ...styles.valueColor, ...styles.bigText}} className="my-1">
    <span>{timestamp}</span>
    <span>{count}</span> 
  </div>

)

const styles: {[key: string]: React.CSSProperties}  = {
  cardGrid: {
    display: 'grid',
    gridGap: '24px',
    gridTemplateColumns: 'minmax(250px, 600px)',
  },

  navHeader: {
    position: 'fixed',
    top: '0',
    width: '100%',
    borderBottom: 'solid .5px rgba(0, 0, 0, .4)',
  },

  globalNav: {
    backgroundColor: '#04060D',
    height: '50px',
    padding: '0px 20px',
  },

  activePage: {
    color: 'white',
    fontWeight: 'bold',
  },

  inactivePage: {
    color: '#B3B3B3',
  },

  navDiv: {
    height: '36px',
    width: '1px',
    backgroundColor: '#ccc',
    margin: '0 16px',
  },

  locationNav: {
    backgroundColor: 'white',
    padding: '10px 0',
    fontSize: '20px',
  },

  card: {
    backgroundColor: 'white',
    boxShadow: '0 2px 6px -3px rgba(0, 0, 0, .15)',
    padding: '24px 20px',
    border: 'solid 1px rgba(0, 0, 0, .1)',
  },

  bigText: {
    fontSize: '20px'
  },

  bannerText: {
    fontSize: '25px'
  },

  valueColor: {
    color: '#333',
  },

  iconXSpacing: {
    marginLeft: '8px',
  },

  labelText: {
    color: '#666',
    fontSize: '16px',
  },

  inputCount: {
    padding: '4px',
    border: 'solid 1px #CCCccc',
    color: '#333',
    fontSize: '20px',
    width: '150px'
  },

  buttonRadius: {
    borderRadius: '4px',
  },

  submitInactiveButton: {
    backgroundColor: '#666',
    color: 'white',
    padding: '4px 16px',
    fontSize: '18px'
  },

  submitActiveButton: {
    backgroundColor: '#5376E6',
    color: 'white',
    padding: '4px 16px',
    fontSize: '18px'
  },

  recentCountsTable: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyItems: 'start',
    gridGap: '4px'
  },

  tableHeader: {
    color: '#666',
    borderBottom: 'solid 1px #ccc',
  },
}

export default LiveData;

import * as React from 'react';
import styled from 'styled-components';
import { FaChevronDown, FaArrowRight} from 'react-icons/fa';
import picLogo from '../../assets/picLogo.svg';
import picTextLogo from '../../assets/picTextLogo.svg';
import moment from 'moment';
import { api, useInterval } from '../../util';
import * as types from '../../types';

const validCount = (c: string) => /^\d+$/.test(c) && parseInt(c) >= 0;

async function sendCount(id: number, headcount: string, estimation: number) {
  if (!validCount(headcount)) {
    alert('Invalid Headcount. Please ensure the value is a number and non-negative in value.');
    return;
  }

  try {
    await api.postHeadcount(id, parseInt(headcount), estimation);
    alert('Headcount successfully made!');
  } 
  catch(err) {
    alert('Error making headcount. Please email "info@occuspace.io" with the count and the time this count was made.');
  }
  window.location.href = '/';
}


interface DataProps {
  cardData: types.ZoneCardData[]
  customer: types.Customer
  uninstalledZones: types.Zone[]
}

export default function Data(props: DataProps) {
  const [cardData, setCardData] = React.useState<types.ZoneCardData[]>(props.cardData);
  const [inputs, inputCount] = React.useState(cardData.map(d => ''));
  const [uninstalledZones] = React.useState<types.Zone[]>(props.uninstalledZones);

  useInterval(() => {
    api.getZoneCards()
      .then(d => setCardData(d));
    // @TODO get uninstalled zones too
  }, 60 * 1000)
  
  return (
    <div>
      <NavHeader>
        <GutterDiv>
          <LogoContainer>
            <img alt="logo" src={picLogo} className="d-flex d-sm-none" />
            <img alt="branding text" src={picTextLogo} className="d-none d-sm-flex" />
          </LogoContainer>
          <span id="PageTitle">
            {props.customer.name} | Accuracy Verification
          </span>
        </GutterDiv>
      </NavHeader>

      <GutterDiv>
    {/* If no zones are plugged in... they're probably in transit */}
        {!cardData.length && (
          <BodyGrid>
            <WelcomeMsg>Welcome to Occuspace!</WelcomeMsg>
            <Explanation>This tool will help you see how accurate we are while you're trying us out :)</Explanation>
            <Instructions>
              Once your devices are installed in the agreed upon locations, you'll start seeing realtime data flowing through here.<br></br>
              There will be an opportunity to input the actual realtime count, so if we're wrong, we can use your inputted counts for system calibration.
            </Instructions>
          </BodyGrid>
        )}

    {/* If at least zone is plugged in */}
        {cardData.length > 0 && (
          <BodyGrid>
          {uninstalledZones.map((zone, idx) => (
            <ZoneCard key={`uinst_${zone.zoneId}`}>
              <VisibleCardContent uninstalledZone>
                <ZoneNameText uninstalled>{zone.zoneName}</ZoneNameText>
                <div>Please install devices in zone to get realtime data in this zone</div>
              </VisibleCardContent>
            </ZoneCard>
          ))}

          {cardData.map((zone, idx) => (
            <ZoneCard key={zone.zoneId}>

              <VisibleCardContent>

                <ZoneNameText>
                  {zone.zoneName}
                </ZoneNameText>

                <ButtonGroup
                  isValidInput={validCount(inputs[idx])}
                >
                  <div>
                    <input 
                      value={inputs[idx]}
                      type="string" 
                      placeholder="Input Current Count" 
                      onChange={evt => {
                        let ogInputs = [...inputs];
                        ogInputs[idx] = evt.target.value;
                        inputCount(ogInputs);
                      }}
                    />
                    <button 
                      onClick={() => sendCount(zone.zoneId, inputs[idx], zone.count)}
                    >
                      <span>Submit</span>
                      <FaArrowRight />
                      {/* only works in mobile: <i className="fa fa-arrow-right m-auto w-100" /> */}
                    </button>
                    <div id="ghost" />
                  </div>
                </ButtonGroup>

                <ZoneStats>
                  <div>
                    <LabelText>
                      Estimate ({moment().format('h:mma')})
                  </LabelText>
                    <ValueText>
                      {Math.max(zone.count, 0)}
                  </ValueText>
                  </div>
                  <div>
                    <LabelText>
                      All-Time Accuracy
                  </LabelText>
                    <ValueText>
                      {zone.verificationCounts.length === 0 ? "-" : `${zone.accuracy}%`}
                    </ValueText>
                  </div>
                </ZoneStats>

              </VisibleCardContent>

              <ExpandContainer>
                <input type="checkbox" id="ExpandChecker"></input>
                <div className="ExpandContents">
                  <HistoricalContainer>
                    <h5>
                      All Headcounts
                    </h5>
                    <TableRow id="HeaderRow">
                      <LabelText>
                        Timestamp
                    </LabelText>
                      <LabelText>
                        Count Inputted
                    </LabelText>
                      <LabelText>
                        Estimated
                    </LabelText>
                    </TableRow>

                    {zone.verificationCounts.map(vc => (
                      <TableRow key={zone.zoneId + vc.timestamp} className="ValueRow">
                        <ValueText>
                          {vc.timestamp}
                        </ValueText>
                        <ValueText>
                          {vc.headcount}
                        </ValueText>
                        <ValueText>
                          {vc.currentEstimation}
                        </ValueText>
                      </TableRow>
                    ))}
                  </HistoricalContainer>
                </div>
                <div id="ChevronContainer">
                  <FaChevronDown id="Chevron" />
                </div>
              </ExpandContainer>
            </ZoneCard>
          ))}

          </BodyGrid>
        )}
      </GutterDiv>

    </div>
  )
}



const OCCU_BLACK = '#14151a';
const OCCU_BLUE = '#0F69AF';
const OCCU_LABEL_COLOR= '#6A6A73'
const BUTTON_HEIGHT= '36px'; 

const GutterDiv = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  max-width: 550px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const NavHeader = styled.div`
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${OCCU_BLACK};
  color: white;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #PageTitle {
    font-weight: bold;
    font-size: 11px
  }

  @media screen and (min-width: 375px) {
    #PageTitle {
      font-size: 14px;
    }
  }
  `

const LogoContainer = styled.div`
  height: 30px;
  
  img {
    height: 100%
  }
`

const BodyGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 32px;
  padding-top: 24px;
  justify-content: center;
`

// before installation, here's a way to have a pure empty state
const WelcomeMsg = styled.h4``
const Explanation = styled.section``
const Instructions = styled.section``

const ZoneCard = styled.div`
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .25);
  border-radius: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${OCCU_BLACK}
`

const VisibleCardContent = styled.div<{uninstalledZone?: boolean}>`
  padding: 16px;
  display: grid;
  grid-gap: ${props => props.uninstalledZone ? '8px' : '32px'};
`

const ZoneNameText = styled.h2<{uninstalled?: boolean}>`
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.uninstalled ? 'red' : 'inherit'};
`

const ButtonGroup = styled.div<{ isValidInput: boolean }>`
  justify-self: center;

  div {
    position: relative;
    width: 200px;
    border-radius: 4px;
    height: ${BUTTON_HEIGHT};
    display: flex;
    align-items: center;
    /*background-color: ${props => props.isValidInput ? 'pink' : 'green'} !important;*/

    
    input {
      border: none;
      font-size: 16px;
      width: 85%;
      background-color: transparent;
      z-index: 10;
      padding-left: 8px;
      border-radius: 4px 0 0 4px;
    }

    input:focus {
      outline: none;
    }

    button {
      background-color: transparent;
      color: #7d7d89;
      height: 24px;
      border: none;
      border-left: solid 1px #ccc;
      width: 30px;
      display: flex;
      align-items: center;
      z-index: 10;
      //transition: left .3s ease, width .3s ease, background-color .3s ease, border-left .3s ease;
      transition: all .4s ease, height 0s;
      border-radius: 0 4px 4px 0;
      position: absolute; 
      left: 85%

      span {
        max-width: 0;
        overflow: hidden;
        color: white;
        font-weight: bold;
        transition: all .4s ease;
      }

      svg{
        transition: all .3s ease;
      }

    }

    ${props => props.isValidInput ? `
    input {
      width: 40%!important;
      height: 36px;
      background-color: white;
    }
    
    button {
      background-color: ${OCCU_BLUE}!important;
      width: 120px;
      height: 36px!important;
      border-left: none;
      left: 40%;
      justify-content: center;
    }

    span {
      width: fit-content;
      max-width: fit-content!important;
      margin-right: 4px;
      overflow: visible;
    }

    svg {
      color: white;
    }

    #ghost {
      box-shadow: 2px 2px 6px rgba(0, 0, 0, .3)!important;
    }
    ` : ``}

    #ghost {
      background-color: #f5f7fa;
      width: 100%;
      height: 100%;
      z-index: 1;
      border: solid 1px #f5f7fa;
      border-radius: 4px;
      position: absolute;
      transition: all .3s ease;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    }

    input:hover ~ #ghost {
      background-color: white;
      border: solid 1px #e6e6e6;
    }

    input:focus ~ #ghost {
      background-color: white;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, .3);
      //box-shadow: 0 0 0 2.6px rgba(15, 105, 175, .3);
      //border: .5px solid ${OCCU_BLUE};
      
    }
  }
`

const ZoneStats = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  justify-items: center;

  div {
    display: flex;
    flex-direction: column
  }
`

const ExpandContainer = styled.div`
  align-self: flex-end;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;

  div::-webkit-scrollbar {
    background-color: white;
    z-index: 1;
  }


  .ExpandContents {
    height: 0px;
    transition: all .3s ease;
    width: 100%;
    opacity: 0;
    overflow-y: hidden;

    div::-webkit-scrollbar {
      background-color: white;
      z-index: 1;
    }
  }

  #Chevron {
    // color: #494953;
    color: ${OCCU_BLUE};
    font-size: 22px;
    transition: all .3s ease;
  }

  #ExpandChecker {
    width: 100%;
    opacity: 0;
    position: absolute;
    bottom: 0;
    z-index: 10;
    height: 32px;
    cursor: pointer;
  }

  #ChevronContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    border-radius: 0 0 6px 6px;
    background-color: Transparent;
    width: 100%;
    cursor: pointer;
    transition: all .3s ease;
  }



  #ExpandChecker:checked ~ #spacing {
    opacity: .9!important;
    height: 1px;
  }

  #ExpandChecker:hover ~ #ChevronContainer {
    background-color: #F8F8FC;
  }

  #ExpandChecker:checked ~ #ChevronContainer #Chevron {
    transform: rotate(-180deg);
  }

  #ExpandChecker:checked ~ .ExpandContents {
    height: 250px;
    overflow-y: scroll;
    opacity: 1;
}
`

const HistoricalContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px;
  transition: all .3s ease;
  background-color: white;
  overflow-x: scroll;
  position: relative;
  display: flex;
  flex-direction: column;

  -webkit-scrollbar {
    background-color: transparent;
    z-index: 1
  }

  h5 {
    font-size: 14px;
    margin-bottom 10px;
    color: ${OCCU_BLACK};
  }
  
  #HeaderRow {
    border-bottom: solid 1px #ccc;
    padding-bottom: 2px;
    width: fit-content;
    min-width: 100%;
  }

  .ValueRow span {
    font-size: 16px!important;
  }

  .ValueRow {
    margin: 6px 0;
    width: 100%;
  }

  .ValueRow:last-child {
    margin-bottom: 0;
  }
`

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 140px 110px 110px;
  grid-gap: 24px;
  width: 100%;
  justify-content: space-between;
  
  .HeaderRow {
    background-color: blue;
    padding-top: 2px;
  }
`


const LabelText = styled.span`
  font-size: 14px;
  color: ${OCCU_LABEL_COLOR};

  @media screen and (max-width: 325px) {
      font-size: 12px;
    }
`

const ValueText = styled.span`
  font-size: 22px;
  color: ${OCCU_BLACK};
  margin-top: 2px;
`
